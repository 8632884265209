<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image contact-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Contact</h2>
              <p class="description">
              (주)데이터엔지니어스랩은 여러분의 질문이나 협력 제안, 서비스 문의 등 모든 소통을 환영합니다.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Contact Address Area  -->
    <div class="rn-service-details ptb--150 bg_color--5">
      <v-container>
        <ContactAddress />
      </v-container>
    </div>
    <!-- End Contact Address Area  -->

    
    <!-- Start Google MAp -->
    <div class="google-map rn-contact-map-area position-relative">
      <div class="mapouter">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.2172561668226!2d126.9978609509065!3d37.47919923678359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca10126884765%3A0xe9d1a55ff276050a!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDrsKnrsLDroZw26ri4IDEz!5e0!3m2!1sko!2skr!4v1656637636170!5m2!1sko!2skr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    <!-- End Google MAp -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import ContactAddress from "../../components/contact/ContactAddress";
  // import Contact from "../../components/contact/Contact";
  // import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";
  export default {
    // components: { Header, ContactAddress, Contact, Brand, Footer },
    components: { Header, ContactAddress, Footer },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
      };
    },
  };
</script>



