<template>
  <v-row>
    <!-- Start Single Address  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(address, i) in addressContent"
      :key="i"
    >
      <div class="rn-address" :class="address.isAddress">
        <div class="icon" v-html="iconSvg(address.icon)"></div>
        <div class="inner">
          <h4 class="heading-title">{{ address.title }}</h4>
          <p>
            <a :href="address.to1">{{ address.info1 }}</a>
          </p>
          <p>
            <a :href="address.to2">{{ address.info2 }}</a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        addressContent: [
          {
            icon: "map",
            title: "주소",
            info1: "서울특별시 서초구 방배로6길 13",
            info2: "배영빌딩 303호",
          },
          {
            icon: "phone",
            title: "전화번호",
            info1: "Tel : +82-2-3453-8124",
            info2: "Fax : +82-2-3453-8124",
          },
          {
            icon: "mail",
            title: "이메일",
            info1: "admin@dlab.kr",
            info2: "",
            isAddress: "addres-last",
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss">
  .addres-last {
    p {
      pointer-events: none;
    }
  }
</style>
